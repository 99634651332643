import React, { Fragment, useRef, useState } from "react";
import { useGlobal } from "../components/Context/Context";
import { db } from "../components/firebase/firebase";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
import ReactToPrint from "react-to-print";
import logo from "../assets/PAMB LOGO ON BLACK.png";
import step1 from "../assets/payment_step1.png";
import step2 from "../assets/payment_step2.png";
import step3 from "../assets/payment_step3.png";
import step4a from "../assets/payment_step4a.png";
import step4b from "../assets/payment_step4b.png";
import step5 from "../assets/payment_step5.png";
import step6 from "../assets/payment_step6.png";
import step7a from "../assets/payment_step7a.png";
import step7b from "../assets/payment_step7b.png";


  let currentDate = new Date();
  let dayOfMonth = currentDate.getDate();
  let timestamp = currentDate.getTime();
  let seed = (dayOfMonth * 10000) + timestamp;
  let randomNumber = Math.floor(seed + Math.random() * 10000) % 10000;
  let transactionId = currentDate.toISOString().substring(0,8) + randomNumber.toString().padStart(4, '0');
 

function Reciept() {

  const currentDate = new Date();
  let componentRef = useRef(null);

  //function generateTxnId() {
   // const dayOfMonth = currentDate.getDate();
   // const timestamp = currentDate.getTime();
    //const seed = (dayOfMonth * 10000) + timestamp;
    //const randomNumber = Math.floor(seed + Math.random() * 10000) % 10000;
    //return currentDate.toISOString().substring(0,8) + randomNumber.toString().padStart(4, '0');
  //}
  
  
  //const transactionId = generateTxnId();

 
  const { selectedTime, selectedDate, visitors, setVisitors, setSelectedTime } =
    useGlobal();

  //let transactionId = "";

 // if (!txnId) {
   // transactionId = generateTxnId();
    //setTxnId(true);
  //}
  
  let total = 0;
  const navigate = useNavigate();
  const [isSuccessfulOpen, setIsSuccessfulOpen] = useState(false);
  const [isDangerOpen, setIsDangerOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrinted, setIsPrinted] = useState(false);
  

  useEffect(() => {
    if (!selectedTime && !selectedDate) {
      setIsDangerOpen(true);
    }
  }, [setIsDangerOpen, selectedTime, selectedDate]);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures that this effect runs only once

  const handleClickBook = async () => {
    setIsLoading(true);
    const visitorRef = doc(db, "visitors", transactionId);
    const timeRef = doc(
      db,
      "bookDetails",
      selectedDate.toDate().toDateString(),
      "bookTime",
      selectedTime
    );

    const docSnap = await getDoc(timeRef);

    if (docSnap.exists()) {
      if (docSnap.data().visitorsNumber + visitors.length > 10) {
        setIsLoading(false);
        setIsWarningOpen(true);
        return;
      }
      await updateDoc(timeRef, {
        visitorsNumber: docSnap.data().visitorsNumber + visitors.length,
      });
    } else {
      if (visitors.length > 10) {
        setIsLoading(false);
        setIsWarningOpen(true);
        return;
      }
      await setDoc(timeRef, {
        time: selectedTime,
        visitorsNumber: visitors.length,
      });
    }

    await setDoc(visitorRef, {
      transactionNumber: transactionId,
      scheduledDate: selectedDate.toDate().toDateString(),
      scheduledTime: selectedTime,
      visitorInfo: visitors,
      totalPrice: total,
      paymentStatus: "Pending",
    });
    setIsLoading(false);
    setIsSuccessfulOpen(true);
  };

  return (
    <>
      <div className="min-h-screen bg-gray-100">
        <div className="sticky top-0 bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 w-full">
          <p className="font-bold">IMPORTANT! SAVE printed copy, click "CONFIRM Booking", and complete the PAYMENT PROCEDURE.</p>
          <p>
            1. <strong>Save a printed copy</strong> of below receipt and payment procedure as this will serve as proof of your booking.
             <br />
            2. Make sure to confirm your booking by clicking <strong>"CONFIRM Booking"</strong>.
             <br />
            3. You will be redirected to <strong>LINK.BIZ portal</strong> to complete the payment procedure.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <div
            className="w-full flex justify-center items-center"
            ref={componentRef}
          >
            <div className="lg:w-3/5 bg-white shadow-lg p-10">
              <div className="flex justify-between pt-4 md:px-4 px-2">
                <div className="w-full flex justify-between flex-row gap-2">
                  <div>
                    <h1 className="text-3xl italic font-extrabold  text-green-900">
                      MPPMNGPL
                    </h1>
                    <p className="text-lg">
                      Order of Payment No.: <strong>{transactionId}</strong>
                    </p>
                    <p className="text-base">
                      Date: {currentDate.toLocaleString().toString()}
                    </p>
                  </div>
                  <div>
                    <img
                      className="md:w-[90px] md:h-[90px] h-[60px] w-[60px] mb-1"
                      src={logo}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-0.5 bg-green-900"></div>
              <div className="flex justify-between p-4">
                <div>
                  <h6 className="font-bold">
                    Booked Date :
                    <span className="pl-2 text-sm font-medium">
                      {selectedDate.toDate().toDateString()}
                    </span>
                  </h6>
                  <h6 className="font-bold">
                    Booked Time :
                    <span className="pl-2 text-sm font-medium">
                      {selectedTime}
                    </span>
                  </h6>
                </div>
              </div>
              <div className="flex justify-center p-4">
                <div className="border-b border-gray-200 shadow overflow-x-auto">
                  <table>
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-1 md:px-4 py-2 text-xs text-gray-500 ">
                          #
                        </th>
                        <th className="px-1 md:px-4 py-2 text-xs text-gray-500 ">
                          Name
                        </th>
                        <th className="px-1 md:px-4 py-2 text-xs text-gray-500 ">
                          Type
                        </th>
                        <th className="px-1 md:px-4 py-2 text-xs text-gray-500 ">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {visitors.map((value, index) => {
                        let price;
                        if (value.citezenship === "Filipino") {
                          price = 200;
                        } else {
                          price = 300;
                        }
                        total += price;
                        return (
                          <tr className="whitespace-nowrap" key={index}>
                            <td className="px-2 md:px-6 py-4 text-sm text-gray-500">
                              {index + 1}
                            </td>
                            <td className="px-2 md:px-6 py-4">
                              <div className="text-sm text-gray-900">
                                {value.fullName}
                              </div>
                            </td>
                            <td className="px-2 md:px-6 py-4">
                              {value.citezenship}
                            </td>
                            <td className="px-2 md:px-6 py-4">₱{price}</td>
                          </tr>
                        );
                      })}

                      <tr className="text-white bg-gray-800 text-center">
                        <th colSpan="2"></th>
                        <td className="text-sm font-bold">
                          <b>Total :</b>
                        </td>
                        <td className="text-sm font-bold">
                          <b>₱{total}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="flex justify-between p-4">
                <div>
                  <h6 className="font-bold">
                    Reminders :
                  </h6>
                  <article>
                    <br />
                    <div className="px-10">
                    <p>
                      (A) Other fees like tour guide and parking are still NOT included on the amount indicated above.
                      <br />
                      (B) Visitors are required to bring government issued IDs and vaccination record. We will only allow entry to fully vaccinated people.
                      <br />
                      (C) Visitors are expected to be on time. There will be a 15-minute Audio Visual Presentation upon arrival. Late comers will be moved to
                       the next available slot or to be accomodated last.
                      <br />
                      (D) Any violation on the following will deny entry of the entire group inside the park:
                      <br />
                      <div className="ml-10">
                        <p>
                          i. No alcoholic drinks allowed.
                          <br />
                          ii. No speakers or instruments permitted.
                          <br />
                          iii. No dangerous weapons.
                          <br />
                          iv. Anyone suspected to be under the influence of alcohol or drugs will be denied entry.
                          <br />
                        </p>
                      </div>
                      <br />
                    </p>
                    </div>
                    <br />
                  </article>
                  <div className="w-full h-0.5 mt-10 mb-10 bg-green-900"></div> 
                  <div>
                    <h6 className="font-bold text-center">STEP-BY-STEP GUIDE ON USING THE LINK.BIZ PORTAL  (Payment Procedure)</h6>
                    <div className="flex justify-between p-4">
                      <div>
                        <article>
                          <br />
                          <div className="px-10">
                            <p>
                              <strong>Step 1.</strong> Go to <span class="underline">www.lbp-eservices.com/egps/portal/index.jsp</span> and click <span class="text-green-600 font-bold">“PAY NOW”</span> to proceed payment.
                            </p>
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step1} alt="Step 1 Payment procedure" />
                            <p>
                              <strong>Step 2.</strong> Select the <span class="text-green-600 font-bold">“DEPARTMNENT OF ENVIRONMENT AND NATURAL RESOURCES- PENRO CAVITE”</span> from the list of merchants then click <span class="font-bold">CONTINUE</span>.
                            </p>
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step2} alt="Step 2 Payment procedure" />
                            <p>
                              <strong>Step 3.</strong> Select <span class="font-bold">“Visitor Fee (MPPMNGPL)”</span> as your transaction and click <span class="font-bold">“CONTINUE”</span>.
                            </p>
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step3} alt="Step 3 Payment procedure" />
                            <p>
                              <strong>Step 4.</strong> Fill-out the Transaction details, choose payment mode and enter the correct <span class="font-bold">CAPTCHA</span>. Check and agree with the Terms and Conditions then click <span class="font-bold">Continue</span>.
                            </p>
                            <img className="max-w-80 mt-5 mb-5 mx-auto" src={step4a} alt="Step 4 Payment procedure" />
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step4b} alt="Step 4 Payment procedure" />
                            <p>
                              <strong>Step 5.</strong> The system shall redirect to the ATM PIN Authentication. Enter the correct One-Time-Password (OTP) sent through your registered Mobile Number/ Email Address and your JAI, then enter your PIN on the PIN pad. Click <span class="font-bold">Submit</span> button to proceed..
                            </p>
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step5} alt="Step 5 Payment procedure" />
                            <p>
                              <strong>Step 6.</strong> The Acknowledgement receipt shall appear for successful payments. The Acknowledgement Receipt can be printed by clicking the Print button.
                            </p>
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step6} alt="Step 6 Payment procedure" />
                            <p>
                              <strong>Step 7.</strong> After a successful payment, send a screenshot or in .pdf file of the Acknowledgement Receipt along with the generated Transaction Receipt from Booking System to <span class="font-bold">penrocavfinance@gmail.com or mppmngpl.picodeloro@gmail.com</span>.
                            </p>
                            <img className="max-w-80 mt-5 mb-5 mx-auto" src={step7a} alt="Step 7 Payment procedure" />
                            <img className="max-w-80 mt-5 mb-10 mx-auto" src={step7b} alt="Step 7 Payment procedure" />
                          </div>
                          <br />
                        </article>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              
                
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-end justify-end space-x-3">
              <ReactToPrint
                trigger={() => {
                  return (
                    <button className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
                      Print
                    </button>
                  );
                }}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  setIsPrinted(true);
                }}
                documentTitle="MPPMNGPL Booking Receipt"
              />
              {isLoading ? (
                <button
                  disabled
                  className="hover:bg-blue-200 cursor-not-allowed inline-flex justify-center rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900"
                >
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-blue-900 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Booking...
                </button>
              ) : (
                <button
                  className={`${
                    !isPrinted
                      ? "bg-gray-200 text-gray-900 hover:bg-gray-200 cursor-not-allowed"
                      : "bg-blue-100 text-blue-900 hover:bg-blue-200"
                  } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                  onClick={handleClickBook}
                  disabled={!isPrinted}
                >
                  CONFIRM Booking
                </button>
              )}
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => navigate("/input_information")}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>

      <Transition appear show={isSuccessfulOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsSuccessfulOpen(false);
            setVisitors([
              {
                fullName: "",
                address: "",
                email: "",
                age: "",
                companySchoolOrg: "",
                contactNumber: "",
                citezenship: "Filipino",
              },
            ]);
            setSelectedTime("");
            navigate("/");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2 mb-10">
                    <p className="mb-2 text-lg font-bold text-red-500">
                      Important Notice:
                    </p>
                    <p className="text-sm text-red-500">
                    Kindly ensure the payment of your reservation within 48 hours. Failure to pay within the prescribed time will result to automatic cancellation of the reserved booking. We appreciate your prompt attention to this matter. Thank you.
                    </p>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Book successful
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Your book is already set. Proceed to payment.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-start">
                    <button
                      type="button"
                      className="mr-4 rounded-md border-2 border-blue-600 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsSuccessfulOpen(false);
                        setVisitors([
                          {
                            fullName: "",
                            address: "",
                            email: "",
                            age: "",
                            companySchoolOrg: "",
                            contactNumber: "",
                            citezenship: "Filipino",
                          },
                        ]);
                        setSelectedTime("");
                        window.location.replace('https://www.lbp-eservices.com/egps/portal/index.jsp');
                      }}
                    >
                      PAY NOW
                    </button>
                    <button
                      type="button"
                      className="ml-4 rounded-md border-2 border-red-600 bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsSuccessfulOpen(false);
                        setVisitors([
                          {
                            fullName: "",
                            address: "",
                            email: "",
                            age: "",
                            companySchoolOrg: "",
                            contactNumber: "",
                            citezenship: "Filipino",
                          },
                        ]);
                        setSelectedTime("");
                        //navigate("/");
                        window.history.replaceState({}, '');
                        window.location.replace('https://mppmngnp.com/');
                      }}
                    >
                      PAY LATER
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isDangerOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsDangerOpen(false);
            navigate("/book_schedule");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border border-red-300 bg-red-50 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Sorry no date found
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please select/reselect date.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white bg-red-900 hover:bg-red-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsDangerOpen(false);
                        navigate("/book_schedule");
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isWarningOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsWarningOpen(false);
            navigate("/book_schedule");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl border border-red-300 bg-red-50 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Maximum slot exceeded
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      The maximum slot per time is exceeded.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white bg-red-900 hover:bg-red-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsWarningOpen(false);
                        navigate("/book_schedule");
                      }}
                    >
                      Book new date
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Reciept;
