export const NAVIGATION_LINK = [
  {
    name: "HOME",
    path: "home",
  },
  {
    name: "ABOUT US",
    path: "about_us",
  },
  {
    name: "VISIT US",
    path: "visit",
  },
  {
    name: "HOUSE RULES",
    path: "activities",
  },
  {
    name: "CONTACT US",
    path: "contact_us",
  },
];
