import React from "react";
import image1 from "../../assets/image1.jpg";
import image2 from "../../assets/image2.jpg";
import image3 from "../../assets/image3.jpg";
import image4 from "../../assets/image4.jpg";
import image5 from "../../assets/image5.jpg";
import image6 from "../../assets/image6.jpg";
import image7 from "../../assets/image7.jpg";
import image8 from "../../assets/image8.jpg";
import image9 from "../../assets/image9.jpg";
import image10 from "../../assets/image10.jpg";
import image11 from "../../assets/image11.jpg";
import image12 from "../../assets/image12.jpg";
import image13 from "../../assets/image13.jpg";
import image14 from "../../assets/image14.jpg";
import image15 from "../../assets/image15.jpg";
import image16 from "../../assets/image16.jpg";
import image17 from "../../assets/image17.jpg";
import image19 from "../../assets/image19.jpg";
import image20 from "../../assets/image20.jpg";
import image28 from "../../assets/image28.jpg";
import image29 from "../../assets/image29.jpg";
import image31 from "../../assets/image31.jpg";
import image32 from "../../assets/image32.jpg";
import image35 from "../../assets/image35.jpg";

function Activites() {
  return (
    <div className="max-w-[1140px] m-auto w-full px-4 py-16" id="activities">
      <div className="p-10 flex flex-col gap-4 text-center">
        <h2 className="text-center text-gray-700 text-3xl font-bold">
          House Rules
        </h2>
        <h1>Follow and respect your eco-guide at all times.</h1>
        <h1>No smoking.</h1>
        <h1>No alcohol or any drug-related substance.</h1>
        <h1>No environmental violation will be tolerated.</h1>
        <h1>Required waiver and assumption of risk.</h1>
        <h1>Pets are not allowed.</h1>
        <h1>The age limit for hikers is 17 years old and above.</h1>
      </div>
      <div className="grid sm:grid-cols-6 gap-4">
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image1} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image2} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image3} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image4} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image5} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image6} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image7} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image8} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image9} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image10} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image11} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image12} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image13} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image14} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image15} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image16} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image17} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image19} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image20} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image28} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image29} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image31} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image32} alt="/" />
        </div>
        <div className="sm:col-span-2 col-span-2 row-span-2">
          <img className="w-full h-full object-cover" src={image35} alt="/" />
        </div>
      </div>
    </div>
  );
}

export default Activites;
